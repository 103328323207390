import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo';
import Layout from '../components/layout';
import TextBanner from '../components/text-banner/text-banner';
import OverlayBlockTeam from '../components/overlay-block-team/overlay-block-team';
import StaffMembersBlock from '../components/staff-block/staff-block';

class ContentAndCreative extends React.Component {
    render() {
        const creativeTextBanner = get(this, 'props.data.creativeTextBanner');
        const creativeOverlayBlockTeam = get(this, 'props.data.creativeOverlayBlockTeam');
        const creativeStaffMembersBlock = get(this, 'props.data.creativeStaffMembersBlock');
        return (
            <Layout location={this.props.location}>
                <Seo title="Meet The Team"/>
                    <div className="container-fluid container-fluid--page">
                        <div className="meet-team">
                            <TextBanner textOne={creativeTextBanner.textOne} textTwo={creativeTextBanner.textTwo} />
                            <OverlayBlockTeam image={creativeOverlayBlockTeam.backgroundImage.url} headingText={creativeOverlayBlockTeam.headingText}
                                descriptionText={creativeOverlayBlockTeam.descriptionText} boxOneNumber={creativeOverlayBlockTeam.boxOneNumber}
                                boxOneText={creativeOverlayBlockTeam.boxOneText} boxTwoNumber={creativeOverlayBlockTeam.boxTwoNumber}
                                boxTwoText={creativeOverlayBlockTeam.boxTwoText} />
                            <StaffMembersBlock members={creativeStaffMembersBlock.nodes} />
                        </div>
                    </div>
            </Layout>
        )
    }
}

export default ContentAndCreative;

export const query = graphql`
query ContentAndCreative {
    creativeTextBanner: contentfulTextBanner(textBannerName: {eq: "creativeTextBanner"}) {
        textBannerName
        textOne
        textTwo
    }
    creativeOverlayBlockTeam: contentfulOverlayBlockTeam(overlayBlockName: {eq: "creativeOverlayBlockTeam"}) {
        overlayBlockName
        headingText
        descriptionText
        boxOneNumber
        boxOneText
        boxTwoNumber
        boxTwoText
        backgroundImage {
            url
        }
    }
    creativeStaffMembersBlock: allContentfulStaffMembersBlock(
        filter: {staffMembersBlockName: {glob: "*creativeStaffMembersBlock*"}}
      ) {
        nodes {
          name
          description
          image {
            url
          }
        }
    }
}
`
