import React from 'react'
import './staff-block.scss'

export default function StaffMembersBlock({ members }) {
    if (!members) return null
    if (!Array.isArray(members)) return null
    return (
        <div className="staff-block">
            <div className="container-fluid">
                <div className="staff-block__container">
                    {members.map((member) => {
                        return (
                            <div className="container-block">
                                <img src={member.image.url} className="container-block-image" width="314" height="354" alt="Staff Member" title="Staff Member"/>
                                <p className="container-block-name">{member.name}</p>
                                <p className="container-block-description">{member.description}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
