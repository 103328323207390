import React from "react"
import './text-banner.scss'

export default function TextBanner(props) {
  return (
    <div className="text-banner">
      <div className="text-banner__container container-fluid">
        <h1 className="text-banner__heading-one">{props.textOne}</h1>
        <h2 className="text-banner__heading-two">{props.textTwo}</h2>
        {props.textThree &&
          <p className="text-banner__heading-three subtitle">{props.textThree}</p>
        }
      </div>
    </div>
  )
}
