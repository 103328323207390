import React from "react"
import './overlay-block-team.scss'

export default function  OverlayBlockTeam(props) {
    return (
        <div className="overlay-block-team" style={{backgroundImage: `url(${props.image})`}}>
            <div className="container-fluid overlay">
                <div className="overlay-block-team__overlay">
                    <div className="overlay-block-team__container">
                        <h2 className="overlay-block-team__heading">{props.headingText}</h2>
                        <p className="overlay-block-team__description">{props.descriptionText}</p>
                    </div>
                </div>
                <div className="overlay-block-team__boxes">
                    <div className="overlay-block-team__languages">
                        <p className="box-number">{props.boxOneNumber}</p>
                        <p className="subtitle">{props.boxOneText}</p>
                    </div>
                    <div className="overlay-block-team__markets">
                        <p className="box-number">{props.boxTwoNumber}</p>
                        <p className="subtitle">{props.boxTwoText}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}